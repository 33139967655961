var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2K4Q7Kv6ultUW_64UA2MM"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
	debug: false,
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	enabled:
		process.env.NEXT_PUBLIC_CLUSTER_ENV !== "dev" &&
		process.env.NEXT_PUBLIC_CLUSTER_ENV !== "preview",
	environment: process.env.NEXT_PUBLIC_CLUSTER_ENV,
	integrations: [
		Sentry.replayIntegration({
			maskAllText: true,
			blockAllMedia: true,
		}),
		Sentry.browserTracingIntegration({
			enableInp: true,
		}),
	],
	replaysOnErrorSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	tracesSampleRate: 0.2,
});
